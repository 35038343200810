// Import required modules
import Template from './dashmix/js/modules/template';

// App extends Template
class AppTemplate extends Template {

  constructor() {
    super();
  }

  helpers(){
    this.helpersOnLoad([
      'jq-magnific-popup',
      'jq-slick',
      'jq-appear',
      'jq-select2',
      'bs-tooltip',
      'bs-popover',
      'dm-toggle-class',
      'dm-year-copy',
      'dm-ripple',
      'dm-fullscreen'
    ])
  }
}

const dashmixStart = () => {
  const DashMix = new AppTemplate();
  DashMix.helpers();
}

// Load vendor libraries
document.addEventListener('turbo:load', dashmixStart);

// Reload vendors in turbo:before-render event
document.addEventListener('turbo:before-render', dashmixStart);
