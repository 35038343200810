import * as bootstrap from 'bootstrap';

function collapseMenu() {
  document.querySelectorAll('#sidebar .nav-link').forEach(function(element){

    element.addEventListener('click', function (e) {
      let nextEl = element.nextElementSibling;
      let parentEl = element.parentElement;

      if (nextEl && nextEl.classList.contains('submenu')) {
        e.preventDefault();
        let mycollapse = new bootstrap.Collapse(nextEl);

        if (nextEl.classList.contains('show')) {
          mycollapse.hide();
        } else {
          mycollapse.show();
      
          var opened_submenu = parentEl.parentElement.querySelector('.submenu.show');

          if (opened_submenu && opened_submenu !== nextEl) {
            new bootstrap.Collapse(opened_submenu);
          }
        }
      }
    });

    element.nextElementSibling.querySelectorAll('.nav-link').forEach(function(subitem) {
      subitem.addEventListener('click', function (e) {
        e.stopPropagation(); //
      });
    });
  });
}

document.addEventListener("DOMContentLoaded", collapseMenu);
document.addEventListener("turbo:load", collapseMenu);
